import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { GrabPayReturnSection } from '../components/sections';

const GrabPayPage = (): JSX.Element => (
  <Layout hideFooter hideHeader>
    <SEO title="GrabPay Payment Result" />
    <GrabPayReturnSection />
  </Layout>
);

export default GrabPayPage;
